import { useLanguage } from "../../../context/LanguageProvider";
import React, { Fragment, useContext, useState } from "react";
import { buyProducts } from "../../../actions";
import { CarContext, CarDispatchContext } from "../../../context/CarContext";
import { Button, Card, CardActions, CardContent, CardHeader, Typography, useTheme } from "@mui/material";
import { BUYING_ACTION, FINISHED_BUYING_ACTION, TYPE_PRODUCT, DELETE_SHOP_CAR_PRODUCT } from "../../../actions/constants";
import ItemShopCar from "../ItemShopCar";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { BuyingContext, BuyingDispatchContext } from "../../../context/BuyingContext";

const ProductTab = (props) => {
    const { languageState } = useLanguage();

    const [anchorEl, setAnchorEl] = useState(null);

    const context = useContext(CarContext);
    const dispatch = useContext(CarDispatchContext);

    const buying = useContext(BuyingContext);
    const dispatchBuying = useContext(BuyingDispatchContext);

    const theme = useTheme();

    const buyProductsCar = () => {
        dispatchBuying({
            type: BUYING_ACTION
        });
        buyProducts({
            data: {
                tipo: TYPE_PRODUCT,
                productos: context.map((e) => {
                    return {
                        id: parseInt(e.id),
                        cantidad: e.cant,
                    };
                }),
                fecha: (new Date()).toDateString(),
            },
        })
            .then((resp) => {
                window.location.replace(resp.data.stripeSession.url);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                dispatchBuying({
                    type: FINISHED_BUYING_ACTION
                });
            });
    };

    return (
      <>
          <Card sx={{ minWidth: 300, height: "100%", boxShadow: "none"  }}>
              <CardContent
                  sx={{
                      padding: "5px",
                      overflow: "auto",
                      maxHeight: "100%",
                      paddingBottom: "52px",
                  }}
              >
                  {context?.map((e) => (
                      <ItemShopCar key={e.id} product={e} actionDelete={DELETE_SHOP_CAR_PRODUCT} contextAction={CarDispatchContext}/>
                  ))}
              </CardContent>
              <CardActions
                  sx={{
                      minWidth: 300,
                      position: "fixed",
                      bottom: 0,
                      right: 0,
                      borderTopColor: "#fff",
                      borderTop: "solid",
                      borderTopWidth: "thin",
                      background: theme.palette.background.paper,
                  }}
              >
                  <Button
                      sx={{
                          width: "100%",
                          backgroundColor: "#f15a24",
                          color: "#fff",
                          "&:hover": {
                              transition: "transform 500ms ease !important",
                              backgroundColor: "rgba(241,90,36,0.45)",
                          },
                      }}
                      disabled={context?.length === 0 || buying}
                      onClick={buyProductsCar}
                      startIcon={<LocalMallOutlinedIcon />}
                  >
                      {buying
                          ? languageState.texts.ShopCar.button.loading
                          : languageState.texts.ShopCar.button.title}
                  </Button>
              </CardActions>
          </Card>
      </>
    );
}

export default ProductTab;