import { createSlice } from "@reduxjs/toolkit";

const initialNews = localStorage.getItem("news");
const initialChannel = localStorage.getItem("channel");

const initialState = {
    user: null,
    news: initialNews ? JSON.parse(initialNews) : {},
    channel: initialChannel ? JSON.parse(initialChannel) : {},
};

const userSlice = createSlice({
    name: "userSlice",
    initialState,
    reducers: {
        signIn: (state, action) => {
            state.user = { ...action.payload };
        },

        signOut: (state) => {
            state.user = null;
        },

        likeAction: (state, action) => {
            state.news[action.payload.id] = state.news[action.payload.id] == "L" ? undefined : "L";
            localStorage.setItem("news", JSON.stringify(state.news));
        },

        dislikeAction: (state, action) => {
            state.news[action.payload.id] = state.news[action.payload.id] == "D" ? undefined : "D";
            localStorage.setItem("news", JSON.stringify(state.news));
        },

        likeChannelAction: (state, action) => {
            state.channel[action.payload.id] =
                state.channel[action.payload.id] == "L" ? undefined : "L";
            localStorage.setItem("channel", JSON.stringify(state.channel));
        },

        dislikeChannelAction: (state, action) => {
            state.channel[action.payload.id] =
                state.channel[action.payload.id] == "D" ? undefined : "D";
            localStorage.setItem("channel", JSON.stringify(state.channel));
        },
    },
});

export const { signIn, signOut, likeAction, dislikeAction, likeChannelAction, dislikeChannelAction } =
    userSlice.actions;
export default userSlice.reducer;
