//material components
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

//icons
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import KeyIcon from "@mui/icons-material/Key";
import LogoutIcon from "@mui/icons-material/Logout";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import DvrIcon from "@mui/icons-material/Dvr";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

//redux
import { useSelector, useDispatch } from "react-redux";
import { signOut } from "../../redux/slices/userSlice";

//react
import { useState } from "react";

//swr
import { mutate } from "swr";

//react-router
import { useNavigate, useLocation } from "react-router-dom";

//actions
import { reloadHeaderLogout } from "../../actions";

//hooks
import { useLanguage } from "../../context/LanguageProvider";
import { useMode } from "../../context/ModeProvider";

const UserMenu = () => {
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const { modeState, setModeState } = useMode();
    const location = useLocation();
    const dispatchReductAction = useDispatch();
    const { languageState } = useLanguage();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggleMode = () => {
        setModeState({ type: "toggle" });
        handleClose();
    };

    const gotoProfile = () => {
        handleClose();
        navigate("/profileform");
    };

    const gotoArtistDashboard = () => {
        handleClose();
        navigate("/dashboard-artist");
    };
    const gotoChat = () => {
        handleClose();
        navigate("/chat");
    };
    const startVideoCall = () => {
        handleClose();
        navigate("/audio_video_call");
    };

    const clearSwrCache = () => mutate((key) => Array.isArray(key), undefined, { revalidate: false });

    const logoutAction = () => {
        sessionStorage.clear();
        localStorage.removeItem("user_id");
        dispatchReductAction(signOut());
        clearSwrCache();
        reloadHeaderLogout();
        handleClose();
        if (location.pathname !== "/") navigate("/");
    };

    return user ? (
        <>
            <IconButton
                id="basic-button"
                color="primary"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <AccountCircleIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem onClick={gotoProfile}>
                    <ListItemIcon>
                        <PersonIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText>{languageState.texts.Menu.profile}</ListItemText>
                </MenuItem>

                {user?.role_name === "Artista/Banda" || user?.role_name === "Profesional" ? (
                    <MenuItem onClick={gotoArtistDashboard}>
                        <ListItemIcon>
                            <DvrIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>{languageState.texts.Menu.artistDashboard}</ListItemText>
                    </MenuItem>
                ) : (
                    ""
                )}
                <MenuItem onClick={gotoChat}>
                    <ListItemIcon>
                        <QuestionAnswerIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText>{languageState.texts.Menu.goToChat}</ListItemText>
                </MenuItem>
                <MenuItem onClick={startVideoCall}>
                    <ListItemIcon>
                        <VideoCallIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText>{languageState.texts.Menu.startVideoCall}</ListItemText>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <KeyIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText>{languageState.texts.Menu.changePassword}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleToggleMode}>
                    <ListItemIcon>
                        {modeState.mode === "light" ? (
                            <DarkModeIcon fontSize="small" color="primary" />
                        ) : (
                            <LightModeIcon fontSize="small" color="primary" />
                        )}
                    </ListItemIcon>
                    <ListItemText>{`${languageState.texts.Menu.changeMode[modeState.mode]}`}</ListItemText>
                </MenuItem>
                <MenuItem onClick={logoutAction}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText>{languageState.texts.Menu.logout}</ListItemText>
                </MenuItem>
            </Menu>
        </>
    ) : (
        <IconButton onClick={handleToggleMode}>
            {modeState.mode === "light" ? (
                <DarkModeIcon fontSize="small" color="primary" />
            ) : (
                <LightModeIcon fontSize="small" color="primary" />
            )}
        </IconButton>
    );
};

export default UserMenu;
