//@mui/material
import { Box, IconButton, useTheme } from "@mui/material";
import React, { useState } from "react";

//icon
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import CloseIcon from '@mui/icons-material/Close';

//component
import FloatChatModal from "./FloatChatModal/FloatChatModal";

const FloatChat = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    
    const handleOpen = () => {
        setOpen(!open);
    };

    return (
        
            <Box
                sx={{
                    position: "fixed",
                    bottom: "120px",
                    right: "0",
                    zIndex: "9999",
                    backgroundColor: theme.palette.primary.main,
                    marginRight: "20px",
                    padding: "3px",
                    borderRadius: "100%",
                }}
            >
                <IconButton onClick={handleOpen} sx={{ color: "white" }}>
                    {open ? (
                        <CloseIcon sx={{ fontSize: "20px" }} />
                    ) : (
                        <ChatBubbleIcon sx={{ fontSize: "20px" }} />
                    )}
                </IconButton>
                <FloatChatModal  open={open} />
            </Box>
        
    );
};

export default FloatChat;
