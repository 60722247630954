import {
    ADD_SHOP_CAR_PRODUCT,
    ADD_SHOP_CAR_TICKET,
    ADD_SHOP_CAR_CROWD_FUNDING,
    DELETE_SHOP_CAR_PRODUCT,
    DELETE_SHOP_CAR_TICKET,
    DELETE_SHOP_CAR_CROWD_FUNDING,
    RESET_SHOP_CAR_PRODUCT,
    RESET_SHOP_CAR_TICKET,
    RESET_SHOP_CAR_CROWD_FUNDING,
    ADD_ARRAY_PRODUCTS_CAR_PRODUCT,
    ADD_ARRAY_PRODUCTS_CAR_TICKET,
    ADD_ARRAY_PRODUCTS_CAR_CROWD_FUNDING,
    BUYING_ACTION,
    FINISHED_BUYING_ACTION
} from "../constants";

export const products = [];
export const tickets = [];
export const crowdFunding = [];
export const buying = false;

const saveChangesSessionStorages = (state) => {
    sessionStorage.setItem("store", JSON.stringify(state));
};

export const buyingReducer = (state, action) => {
    switch (action.type) {
        case BUYING_ACTION:
            return true;
        case FINISHED_BUYING_ACTION:
            return false;
        default:
            return state;
    }
}

export const crowdFundingReducer = (state, action) => {
    switch (action.type) {
        case ADD_SHOP_CAR_CROWD_FUNDING:
            const cf = state.find((e) => e.id === action.item.id);
            if (cf) {
                return state.map((e) => {
                    if (e.id === cf.id) {
                        e.cant = action.cant;
                    }
                    return e;
                });
            } else {
                return [
                    ...state,
                    {
                        ...action.item,
                        cant: action.cant,
                        name: action.item.title,
                    },
                ]
            }
        case DELETE_SHOP_CAR_CROWD_FUNDING:
            return state.filter((e) => e.id !== action.item.id);
        case RESET_SHOP_CAR_CROWD_FUNDING:
            return [];
        case ADD_ARRAY_PRODUCTS_CAR_CROWD_FUNDING:
            return [...state, ...(action.item ?? [])];
        default:
            return state;
    }
}

export const ticketsReducer = (state, action) => {
    switch (action.type) {
        case ADD_SHOP_CAR_TICKET:
            const ticket = state.find((e) => e.id === action.item.id);
            if (ticket) {
                return state.map((e) => {
                    if (e.id === ticket.id) {
                        e.cant += action.cant;
                    }
                    return e;
                });
            } else {
                return [
                    ...state,
                    {
                        id: action.item.id,
                        name: action.item.title,
                        price: 1000,
                        photo: action.item.poster || undefined,
                        cant: action.cant,
                    },
                ]
            }
        case DELETE_SHOP_CAR_TICKET:
            return state.filter((e) => e.id !== action.item.id)
        case RESET_SHOP_CAR_TICKET:
            return [];
        case ADD_ARRAY_PRODUCTS_CAR_TICKET:
            return [...state, ...(action.item ?? [])];
        default:
            return state;
    }
}

export const carShopReducer = (state, action) => {
    switch (action.type) {
        case ADD_SHOP_CAR_PRODUCT:
            const product = state.find((e) => e.id === action.item.id);
            if (product) {
                return state.map((e) => {
                    if (e.id === product.id) {
                        e.cant += action.cant;
                    }
                    return e;
                });
            } else {
                return [
                    ...state,
                    {
                        ...action.item,
                        cant: action.cant,
                    },
                ]
            }
        case DELETE_SHOP_CAR_PRODUCT:
            return state.filter((e) => e.id !== action.item.id);
        case RESET_SHOP_CAR_PRODUCT:
            return [];
        case ADD_ARRAY_PRODUCTS_CAR_PRODUCT:
            return [...state, ...(action.item ?? [])];
        default:
            break;
    }
};
