import { Snackbar, Alert } from "@mui/material";
const CustomSnackbar = ({snackbar, setSnackbar}) => {
    const handleCloseSnackbar = () => setSnackbar(null);
    return (
        !!snackbar && (
            <Snackbar
                open
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
            >
                <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
        )
    )
}

export default CustomSnackbar;