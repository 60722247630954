import useSWR from "swr";
import axios from "axios";

const useFetchFilterUsers = (url) => {
   const fetcher = async (apiURL) =>{
         try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            });
            return response;
        } catch (error) {
            return;
        }

    }
    const { data, error } = useSWR(url, fetcher);

    return { data, error };
};

export const detailsGroup = (url) => {
    const headers = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    };
   return axios
       .get(url, headers)
       .then((data) => data)
       .catch((error) => error);
};

export const sendMessage = (url, formdata) => {
    console.log("formdata", formdata);
     const headers = { Authorization: `Bearer ${sessionStorage.getItem("token")}` };
    return axios
        .post(url,formdata,{
        headers,
    })
        .then((data) => data)
        .catch((error) => error);
};
export const sendMessageNotAuthenticated = (url, formdata) => {
    console.log("formdata", formdata);
    return axios
        .post(url, formdata, {
        })
        .then((data) => data)
        .catch((error) => error);
};

export const floatChat = (url) => {
    const headers = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    };
    if (!sessionStorage.getItem("token")) {
        return
    }
    return axios
        .get(url, headers)
        .then((data) => data)
        .catch((error) => error);
};


export default useFetchFilterUsers;
