
import PropTypes from "prop-types";

import { Box} from "@mui/material";

import EmojiPicker from "emoji-picker-react";

//context
import { useLanguage } from "../../../context/LanguageProvider";


const Emojis = ({ pickEmoji}) => {
    const { languageState } = useLanguage();
  
   
    const categoriesSpanish = [
        { category: "suggested", name: languageState.texts.Chat.CategoriesSpanish.Suggested },
        { category: "smileys_people", name: languageState.texts.Chat.CategoriesSpanish.Smileys_people },
        { category: "animals_nature", name: languageState.texts.Chat.CategoriesSpanish.Animals_nature },
        { category: "food_drink", name: languageState.texts.Chat.CategoriesSpanish.Food_drink },
        { category: "travel_places", name: languageState.texts.Chat.CategoriesSpanish.Travel_places },
        { category: "activities", name: languageState.texts.Chat.CategoriesSpanish.Activities },
        { category: "objects", name: languageState.texts.Chat.CategoriesSpanish.Objects },
        { category: "symbols", name: languageState.texts.Chat.CategoriesSpanish.Symbols },
        { category: "flags", name: languageState.texts.Chat.CategoriesSpanish.Flags },
    ];

    
    const pickerStyle = {
        width: "100%",
        height: "20em"
    };

    return (
        <Box sx={{ width: "100%", height: "auto", overflowY: "auto", paddingX: "10px" }}>
            <EmojiPicker
                onEmojiClick={pickEmoji}
                style={pickerStyle}
                previewConfig={{ showPreview: false }}
                searchPlaceholder={languageState.texts.Chat.CategoriesSpanish.SearchPlaceholder}
                categories={categoriesSpanish}
            />
        </Box>
    );
};

Emojis.prototypes = {
    pickEmoji: PropTypes.func,
};

export default Emojis;
