import { Box} from '@mui/material';

//icon
import Brightness1Icon from "@mui/icons-material/Brightness1";

const TypingAnimation = () => {
  return (
      <div className="typing-animation">
          <span className="typing-indicator">
              <Box
                  sx={{
                      
                      display: "flex",
                      alignItems: "center",
                  }}
              >
                  <Brightness1Icon
                      sx={{
                          width: "8px",
                          height: "8px",
                          color: "green",
                          marginRight: "2px",
                      }}
                  />
                  <Brightness1Icon
                      sx={{
                          width: "8px",
                          height: "8px",
                          color: "pink",
                          marginRight: "2px",
                      }}
                  />{" "}
                  <Brightness1Icon
                      sx={{
                          width: "8px",
                          height: "8px",
                          color: "orange",
                          marginRight: "2px",
                      }}
                  />
              </Box>
          </span> 
      </div>
  );
}

export default TypingAnimation
