import useSWR from "swr";
import axios from "axios";

const useFetchPlaylistFromStrapi = (playlistApi) => {
    const fetcher = (apiURL) =>
        axios
            .get(apiURL)
            .then((data) => data)
            .catch((error) => error);

    const { data, error } = useSWR(playlistApi, fetcher);

    return { data, error };
};

 export const useFetchFilterMusic = (url) => {
    const fetcher = async (apiURL) => {
        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            });
            return response;
        } catch (error) {
            return;
        }
    };
    const { data, error } = useSWR(url, fetcher);

    return { data, error };
};

export default useFetchPlaylistFromStrapi;
